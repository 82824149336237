import React, { useEffect, useRef, useState } from 'react'
import './upload-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { usePapaParse } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { membershipRestrictions } from '@/utils/membershipRestrictions';
import { RandomString } from '@/utils/randomString';
// import csv from '@/assets/files/GamablySecretCodeUploadTemplate.csv'

const SecretCodeUploadModal = ({ show, cancel, gameData }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ arrToUpload, setArrToUpload ] = useState([]);
    const [ availableSpots, setAvailableSpots ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const fileReader = new FileReader();
    const { readString } = usePapaParse();
    const approveRef = useRef();

    useEffect(() => {
        if (!membership || !membershipRestrictions) return;
        const num = membershipRestrictions[membership.membership].elementCount;
        if (num === Infinity) {
            setAvailableSpots('Unlimited');
            return;    
        }
        setAvailableSpots(num - gameData.createdElementTotal);
    }, [membership, membershipRestrictions])

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    async function handleUpload(file) {
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['csv']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            setArrToUpload([]);
            document.getElementById('fileUpload').value = null;
            return;
        }
        const csvOutput = await readFileAsync(file)
		// console.log(csvOutput)
		const arr = await getParsedData(csvOutput)
        console.log(arr);
		const objArr = createObjs(arr)
        console.log(objArr);
        setArrToUpload(objArr);
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                resolve(event.target.result);	
            };
            fileReader.readAsText(file);
        })
    }

    function getParsedData(arr) {
		return new Promise((resolve, reject) => {
			readString(arr, {
		      	worker: true,
		      	complete: (results) => {
			        // console.log('---------------------------');
			        // console.log(results.data);
			        // console.log('---------------------------');
					resolve(results.data)
			  	},
		    });
		})
	}

    function createObjs(arr) {
        const template = [
            "Code",
            "Description",
            "Category",
            "Prerequisite Points",
            "Minimum Level",
            "Prerequisite Badge(s)",
            "Prerequisite Item(s)",
            "Group Requirement",
            "Minimum XP Earned",
            "Maximum XP Earned",
            "Minimum Currency Earned",
            "Maximum Currency Earned",
            "Badge(s) Earned",
            "Item(s) Earned",
            "Prize Pack Earned",
            "Minimum Prize Pack Items Earned",
            "Maximum Prize Pack Items Earned",
            "Available Date",
            "Unavailable Date"
        ]
		const headers = arr[0]
        console.log(template);
        console.log(headers);
        let validCSV = true;
        for (let i=0; i<template.length; i++) {
            if (template[i] !== headers[i]) {
                validCSV = false;
                break;
            }
        }
		if (!validCSV) {
            setAlertMessage('Please use the correct template when uploading a list of Secret Codes.');
            return false;
        }
        let objArr = [];
        let startingPoint = 1;
        if (arr[1][0].includes('required')) {
            startingPoint = 2;
        }
        const keyList = [
            "name",
            "desc",
            "opt_cat",
            "opt_prereqPoints",
            "opt_prereqLevel",
            "opt_prereqBadges",
            "opt_prereqItems",
            "opt_prereqGroups",
            "opt_earnedPointsMin",
            "opt_earnedPointsMax",
            "opt_earnedCurrencyMin",
            "opt_earnedCurrencyMax",
            "opt_earnedBadges",
            "opt_earnedItems",
            "opt_earnedPrizePack",
            "opt_earnedPrizeMin",
            "opt_earnedPrizeMax",
            "opt_dateOpen",
            "opt_dateClose"
        ]
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6);
        const len = arr.length <= availableSpots ? arr.length : availableSpots;
		for (let a=startingPoint; a<len + startingPoint; a++) {
			const row = arr[a];
			let obj = {};
            
			for (let r=0; r<row.length; r++) {
				if (row[r] && (r === 3 || r === 8 || r === 9 || r === 10 || r === 11 || r === 15 || r === 16)) {
                    obj[keyList[r]] = Number(row[r])
                } else if (row[r] && (r === 17 || r === 18)) {
                    obj[keyList[r]] = new Date(row[r]).getTime();
                } else if (row[r]) {
                    obj[keyList[r]] = row[r]
                }
			}
            obj.appId = userProfile.appId;
            obj.code = obj.name.toUpperCase().replace(/\s/gi,'');
            obj.ts_created = now + a;
            obj.ts = now + a;
            obj.gameId = gameData.gameId;
            obj.icon = "/images/icons/secret.png";
            obj.id = obj.ts + '-' + gameCode + '-secretCode';
            obj.sortGameType = gameData.gameId + '-secretCode';
            obj.status = 'active';
            obj.type = 'secretCode';
            obj.opt_visible = true;
            obj.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            obj.opt_available = true;
			objArr.push(obj)
		}
		return objArr;
	}

    function cancelUpload() {
        setArrToUpload([]);
        document.getElementById('fileUpload').value = null;
        cancel();
    }

    function proceedWithUpload() {
        setConfirmMessage(`Are you sure you are ready to upload ${arrToUpload.length} Secret Codes?`);
        async function uploadList() {
            setLoading(true);
            const res = await UseCloudFunction('uploadElements', {'arr': arrToUpload, 'appId': userProfile.appId, 'gameId': gameData.gameId, 'userName': `${userProfile.fName} ${userProfile.lName}`})
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Details: ' + res.error);
                return;
            }
            setLoading(false);
            cancelUpload();
        }
        approveRef.current = () => {
            uploadList();
        }
    }

    return (
        <div className='player-upload-modal'>
            <Modal show={show} cancel={cancel} closeButton={true} text='Upload a List of Codes' cls='narrow' >
                <div className='modal-children'> 
                    <p><b>Available Spots: {availableSpots}</b></p>
                    <p>To upload a list of Secret Codes, make a copy of <b><a href='https://docs.google.com/spreadsheets/d/1rWig0E6sjmLk5qilY3Fs4PyEmgsbPb86VE2XhyJz3gA/copy' target="_blank">THIS GOOGLE SHEET</a></b> and export it as a CSV or download <b><a href={`${import.meta.env.VITE_ROOT}/images/files/GamablySecretCodeUploadTemplate.csv`} target='_blank' download>THIS CSV</a></b>. Then, upload the completed roster here.</p>
                    <div className="g-space-1"></div>
                    <div style={{cursor: 'pointer'}}>
                        <input type="file" id="fileUpload" accept=".csv" onChange={(e) => handleUpload(e.target.files[0])}  />
                        <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload a List of Secret Codes</button>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (arrToUpload.length > 0 && !loading) &&
                        <div className='upload-list'>
                            <hr />
                            <p><b>List of Codes to be Uploaded</b></p>
                            {
                                arrToUpload.map(c => (
                                    <div key={c.id} className='upload-list-item'>{c.name}</div>
                                ))
                            }
                            <div className='g-space-1'></div>
                            {
                                (loading)
                                ?
                                <div className='buttons'>
                                    <button type='button' className='g-button'>Cancel</button>
                                    <button type='button' className='g-button' ><ThreeDotsLoader /></button>
                                </div>
                                :
                                <div className='buttons'>
                                    <button type='button' className='g-button' onClick={() => cancelUpload()}>Cancel</button>
                                    <button type='button' className='g-button primary' onClick={() => proceedWithUpload()}>Upload This List</button>
                                </div>
                            }
                            <div className='g-space-1'></div>
                        </div>
                    }
                </div>
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default SecretCodeUploadModal