import React, { useEffect, useRef, useState } from 'react'
import './collections-form.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ManageContentInformation from '../blocks/information.component';
import ManageContentVisibility from '../blocks/visibility.component';
import ManageContentCategories from '../blocks/categories.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import RewardsForm from '../blocks/rewards-form.component';
import ItemSelector from '../blocks/item-selector.component';
import { RandomString } from '@/utils/randomString';
import ManageContentIconCropped from '../blocks/icon.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const CollectionsForm = ({ gameElements, gameData }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ collectionItems, setCollectionItems ] = useState(null);
    const { collectionPath } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();
    const location = useLocation();

    useEffect(() => {
        if (!gameData || !membership || !location) return;
        if (location.pathname.includes('/elements/collections/edit')) return;
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
    }, [gameData, membership, location])

    useEffect(() => {
        if (!collectionPath || !gameElements) {return;}
        dataRef.current = Object.values(gameElements).filter(e => e.path === collectionPath)[0];
        setFormData(dataRef.current);
        console.log(dataRef.current);
        if (dataRef.current.desc) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [collectionPath, gameElements])

    useEffect(() => {
        if (!gameElements) {return;}
        let arr = [];
        for (let c of Object.values(gameElements).filter(c => c.type === 'collection')) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        setCategories(arr.sort())
        let collItems = Object.values(gameElements).filter(e => e.opt_itemType === 'collectible')
        let collObj = {}
        collItems.map(i => collObj[i.id] = i);
        setCollectionItems(collObj);
        console.log(collObj);
    }, [gameElements])

    function saveData(key, value) {
        let tempData = {...dataRef.current};
        if (!value) {
            delete tempData[key];
            dataRef.current = {...tempData};
        } else {
            dataRef.current = {...dataRef.current, ...{[key]: value}};
        }
        setFormData(dataRef.current);
        console.log(dataRef.current);
    }
    
    function cancelCreate() {
        setConfirmMessage('Are you sure you want to cancel? All of your data will be lost.');
        approveRef.current = () => {
            dataRef.current = {};
            setFormData(dataRef.current);
            navigate(-1)
        }
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current}
        const reqs = ['name', 'icon']
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name and an icon for this collection before saving.');
                return;
            }
        }
        setConfirmMessage('Are you sure you are ready to save this Collection? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        console.log(tempData);
        if (tempData.icon.upload) {
			tempData.icon.croppie.result({
				type:'blob',
				size: {'width': 256, 'height': 256},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(tempData.icon, blob)
				return;
			})
		} else if (tempData.icon.url) {
			saveCollection(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            saveCollection(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon, blob) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/collectionIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, blob, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				saveCollection(url)
				return;
			})
		})
	}

    async function saveCollection(iconUrl) {
        let tempData = {...dataRef.current}
        console.log(tempData);
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.id) {
            tempData.appId = userProfile.appId;
            tempData.ts_created = now;
            tempData.gameId = gameData.gameId;
            tempData.id = now + '-' + gameCode + '-collection';
            tempData.status = 'active';
            tempData.type = 'collection';
            tempData.public = true;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            tempData.opt_available = true;
        } 
        tempData.ts = now;
        tempData.icon = iconUrl;
        console.log(tempData);
        const res = await UseCloudFunction('saveGameElement', {'appId': userProfile.appId, 'elementData': tempData, 'userName': `${userProfile.fName} ${userProfile.lName}`})
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Collection saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='create-collection-form'>
            <ManageContentInformation 
                type='collection' 
                saveData={saveData} 
                formData={formData} 
                existingDescription={existingDescription} 
                clearEditor={clearEditor} 
                setClearEditor={setClearEditor}
                textEditorLabel='Collection Explanation (optional)'
            />
            <div className='g-space-2'></div>
            <ManageContentIconCropped
                type='collection' 
                saveData={saveData} 
                formData={formData} 
            />
            <div className='g-space-2'></div>
            
            <ManageContentCategories
                type='collection' 
                saveData={saveData} 
                formData={formData} 
                categories={categories}
            />
            <div className='g-space-2'></div>
            <ManageContentVisibility 
                type='collection' 
                saveData={saveData} 
                formData={formData} 
                previewText='Allow players to see this collection in their "View My Items" list.'
                visibilityText="Turn visibility ON for this Collection. If toggled off, it and its included items will be totally hidden from players."
                            
            />
            <div className='g-space-2'></div>
            <ItemSelector 
                type='collection' 
                formData={formData} 
                saveData={saveData} 
                gameElements={collectionItems} 
                gameData={gameData}
                saveKey='opt_itemList'
                headText="Select Items for this Collection"
                inst="Choose the items you want to include in this collection. Once all of the items are earned, a player will receive the rewards you choose below."
            />
            <div className='g-space-2'></div>
            <div className='manage-content-rewards g-list-item form-section'>
                <h3>Rewards</h3>
                <hr />
                <p>Players can earn rewards for earning all of the items in this collection. Leave any of these items blank if you do not want to include them as rewards.</p>
                <div className="rewards-content">
                    <RewardsForm 
                        type='collection' 
                        formData={formData} 
                        saveData={saveData} 
                        gameElements={gameElements} 
                        gameData={gameData}
                    />
                </div>
            </div>
            {/* <div className='g-space-2'></div>
            <ManageContentDates
                type='collection' 
                saveData={saveData} 
                formData={formData} 
                gameElements={gameElements}
                text='collections'
            /> */}
            <div className='g-space-2'></div>
            <span className='required'>* = Required</span>
            <div className='buttons'>
                {
                    (loading) 
                    ?
                    <>
                        <button type='button' className='g-button'>Cancel</button>
                        <button type='button' className='g-button'><ThreeDotsLoader /></button>
                    </>
                    :
                    <>
                        <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                        <button type='button' className='g-button submit-button' onClick={() => handleSubmit()}>Save Collection</button>
                    </>
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                message={showUpgradeModal}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default CollectionsForm