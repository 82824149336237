import React, { useEffect, useRef, useState } from 'react'
import './home-page.styles.scss';

import Layout from '@/components/layout-auth/layout.comp'
import HomeLanding from '@/components/HOME/home-landing/home-landing.component'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import EnrolledContainer from '@/components/HOME/enrolled/enrolled-container.conponent'
import PageNotFound from '../page-not-found/page-not-found.component'
import ManagingContainer from '@/components/HOME/managing/managing-container.component'
import CreateEditGameContainer from '@/components/HOME/create-edit-game/create-edit-game-container.component'
import { getGameDataFromDb, getOrgDataFromDb, getOrgInvitesFromDb, getRegsFromDb, getUniqueDocumentFromDb } from '@/utils/getDataFromDb';
import { useDispatch, useSelector } from 'react-redux';
import { setEnrolledList, setManagingList } from '@/state/slices/contentSlice';
import { auth } from '@/utils/firebase.utils';
import BugModal from '@/components/layout-auth/bug-modal/bug-modal.component';
import JoinGameWithEmail from '@/components/HOME/join-game-with-email/join-game-with-email.component';
import MyPlan from '@/pages/my-plan/my-plan.component';
import InviteModal from '@/components/HOME/invite-handler/invite-modal.component';
import { setMembership } from '@/state/slices/userSlice';
import MyOrgs from '@/components/HOME/orgs/my-orgs.page';

const HomePage = ({ 
    orgs,
    managingList,
    enrolledList 
}) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const profile = useSelector(state => state.userData.userProfile);
    const [ invites, setInvites ] = useState(null);
    const [ showBugModal, setShowBugModal ] = useState(false);
    const [ selectedInvite, setSelectedInvite ] = useState(null);
    const invitesRef = useRef();
    const navigate = useNavigate();
    let regsListener;
    let invitesListener;

    useEffect(() => {
        // change var(--primary) to default color
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');

        const timeout = setTimeout(() => {
            const currentlySignedInUser = auth.currentUser;
            // console.log(currentlySignedInUser);
            if (!currentlySignedInUser || currentlySignedInUser.providerData.length === 0) {
                navigate('/logout');
            }
        }, 2000);

        return () => {
            // cancels any listeners
            if (regsListener) regsListener();
            if (invitesListener) invitesListener();
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        console.log(enrolledList);
    }, [enrolledList])

    useEffect(() => {
        if (!currentUser) return;
        if (currentUser.provider === 'FirestoreAuth') {
            navigate('/logout');
        }
    }, [currentUser])

    useEffect(() => {
        if (!profile) return;
        getOrgInvites();
    }, [profile])

    useEffect(() => {
        if (!invites) return;
        // console.log(invites);
        const firstInvite = Object.values(invites)
        .filter(i => i.status === 'pending')
        .sort((a,b) => a.ts_created > b.ts_created ? 1 : -1)[0]
        setSelectedInvite(firstInvite);
    }, [invites])

    

    function getOrgInvites() {
        getOrgInvitesFromDb({
            'email': profile.email,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data, id) {
            invitesRef.current = {...invitesRef.current, ...{[id]: data}}
            setInvites(invitesRef.current);
        }
        function handleListener(unsub) {
            invitesListener = unsub;
        }
    }

    return (
        <div className='home-page'>
            <Layout setShowBugModal={setShowBugModal}>
                <Routes>
                    <Route path='' element={<HomeLanding />}></Route>  
                    <Route path='enrolled' element={
                        <EnrolledContainer
                            enrolledList={enrolledList} />
                    }></Route>  
                    <Route path='managing' element={
                        <ManagingContainer 
                            orgs={orgs}
                            managingList={managingList} />
                    }></Route>  
                    <Route path='creategame/*' element={<CreateEditGameContainer />}></Route>
                    <Route path='joingame' element={<JoinGameWithEmail />}></Route>
                    <Route path='joingame/:joinCode' element={<JoinGameWithEmail />}></Route>
                    <Route path='myorgs/*' element={
                        <MyOrgs 
                            orgs={orgs} />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </Layout>
            <BugModal 
                show={showBugModal} 
                cancel={() => setShowBugModal(false)} 
            />
            <InviteModal 
                invite={selectedInvite} 
                cancel={() => setSelectedInvite(null)} 
            />
        </div>
    )
}

export default HomePage