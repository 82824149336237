import React, { useEffect, useRef, useState } from 'react'
import './distribute-page.styles.scss';
import { useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import gem from '@/assets/icons/xp.png';
import coin from '@/assets/icons/coin.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faFlask } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import RewardsForm from '../create-elements/blocks/rewards-form.component';
import { UseCloudDistribute } from '@/utils/firebase.utils';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import PlayerSelector from '@/components/SHARED/player-selector/player-selector.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const DistributePage = ({ 
    gameData, 
    gameElements, 
    playerList,
    teamMembers
}) => {
    
    const userProfile = useSelector(state => state.userData.userProfile)
    const [ formData, setFormData ] = useState({});
    const [ disabled, setDisabled ] = useState(false);
    const [ playersToDisplay, setPlayersToDisplay ] = useState(null);
    const [ chosenPlayers, setChosenPlayers ] = useState([]);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ review, setReview ] = useState(false);
    const [ teamList, setTeamList ] = useState([]);
    const [ distributionObj, setDistributionObj ] = useState({});
    const [ loaderText, setLoaderText ] = useState(null);
    const navigate = useNavigate();
    const dataRef = useRef({'type': 'players'});
    const chosenPlayerRef = useRef();
    const approveRef = useRef();
    const indDistRef = useRef();
    const teamRef = useRef();

    useEffect(() => {
        chosenPlayerRef.current = []
        setFormData(dataRef.current);
    }, [])

    useEffect(() => {
        if (!gameData) {return;}
        // getGamePlayers();
    }, [gameData])

    useEffect(() => {
        console.log(playerList)
        if (!playerList) {return;}
        setPlayersToDisplay(Object.values(playerList).filter(p => !p.team));
        setTeamList(Object.values(playerList).filter(p => p.team));
    }, [playerList])

    useEffect(() => {
        chosenPlayerRef.current = []
        setChosenPlayers(chosenPlayerRef.current);
    }, [formData.type])

    function cancel() {
        if (review) {
            goBack();
        } else {
            navigate(`/manage/${gameData.path}`);
        }
    }

    function togglePlayer(player, choice) {
        let newList = chosenPlayerRef.current ? [...chosenPlayerRef.current] : [];
        if (choice === 'select') {
            if (!newList.includes(player.playerId)) {
                newList.push(player.playerId)
            }
            chosenPlayerRef.current = [...newList];
            setChosenPlayers(chosenPlayerRef.current);
            return;
        }
        if (choice === 'deselect') {
            if (newList.includes(player.playerId)) {
                newList.splice(newList.indexOf(player.playerId), 1);
            }
            chosenPlayerRef.current = [...newList];
            setChosenPlayers(chosenPlayerRef.current);
            return;
        }
        
        if (newList.includes(player.playerId)) {
            newList.splice(newList.indexOf(player.playerId), 1);
        } else {
            newList.push(player.playerId);
        }
        chosenPlayerRef.current = [...newList];
        setChosenPlayers(chosenPlayerRef.current);
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        console.log(dataRef.current);
    }

    // function search(text) {
    //     if (!text) {
    //         setPlayersToDisplay(Object.values(playerList));
    //         return;
    //     }
    //     const list = Object.values(playerList).filter(t => t.fName.toLowerCase().includes(text.toLowerCase()) || t.lName.toLowerCase().includes(text.toLowerCase()) || t.displayName.toLowerCase().includes(text.toLowerCase()))
    //     setPlayersToDisplay(list);
    // }

    function cancelDistribution() {
        setConfirmMessage('Are you sure you want to cancel? Any progress will be lost.')
        approveRef.current = () => {
            dataRef.current = {}
            setFormData(dataRef.current);
            navigate(`/manage/${gameData.path}`);
        }
    }

    function setDefaultDistribution() {
        if (!formData.title) {
            setAlertMessage('Please give this reward a title so your players know why they received it.')
            return;
        }
        const earnedKeys = Object.keys(formData).filter(k => k.includes('opt_earned'));
        if (earnedKeys.length === 0) {
            setAlertMessage('Please choose a default reward before moving on.')
            return;
        }
        let obj = {}
        for (let p of chosenPlayers) {
            obj[p] = {...formData};
            obj[p].playerId = p;
            if (obj[p].title) {
                delete obj[p].title;
            }
            if (obj[p].info) {
                delete obj[p].info;
            }
        }
        indDistRef.current = obj
        setDistributionObj(indDistRef.current);
        setReview(true);
    }

    function saveIndDistribution(key, value, playerId) {
        const tempDistObj = {...indDistRef.current};
        const tempInd = {...tempDistObj[playerId]};
        tempInd[key] = value;
        const newTempDist = {...tempDistObj, ...{[playerId]: tempInd}};
        indDistRef.current = {...newTempDist};
        setDistributionObj(indDistRef.current);
        console.log(indDistRef.current);
        console.log(playerId);
    }
    
    function goBack() {
        setDistributionObj({});
        setReview(false);
    }

    function toggleEditBox(id) {
        document.getElementById(`edit-${id}`).classList.toggle('hidden');
        console.log(distributionObj[id]);
    }

    function distributeRewards() {
        setConfirmMessage('Are you sure you are ready to distribute these rewards as they appear on this page? This action cannot be undone.');
        approveRef.current = () => {
            proceedWithDistribution()
        }
    }

    async function proceedWithDistribution() {
        if (!formData.title) {
            setAlertMessage('Please give this reward a title so your players know why they are receiving it.');
            return;
        }
        setLoading(true);
        let tempData = {...formData};
        tempData.ts = new Date().getTime();
        console.log(tempData)
        console.log(indDistRef.current)
        for (let p of Object.values(indDistRef.current)) {
            setLoaderText(`Processing ${playerList[p.playerId].displayName}...`)
            const res = await UseCloudDistribute(
                'distributeRewards', {
                    'gameId': gameData.gameId, 
                    'playerId': p.playerId,
                    'recipient': p, 
                    'title': tempData.title, 
                    'info': tempData.info, 
                    'ts': tempData.ts,
                    'playerData': playerList[p.playerId],
                }
            )
            console.log(res);
            if (res.error) {
                setLoading(false);
                setAlertMessage('Something went wrong, please try again. Err: ' + res.error)
                return;
            }
        }
        setAlertMessage('Rewards distributed successfully!');
        clearDistribution();
    }

    function clearDistribution() {
        dataRef.current = {}
        setFormData(dataRef.current);
        chosenPlayerRef.current = [];
        setPlayersToDisplay(chosenPlayerRef.current);
        setReview(false);
        setLoading(false);
    }
    
    return (
        <div className='distribute-page-container'>
            <div className='distribute-content g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Distribute Rewards</div>
                <div className='g-space-1'></div>
                {
                    (!review) &&
                    <>
                    <div className='reward-info-container'>
                        <div className='reward-info g-list-item grey-background'>
                            <h3>Reward Information</h3>
                            <hr />
                            <div className='field'>
                                <label>Reward Title</label>
                                <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
                            </div>
                            <div className='g-space-1'></div>
                            <div className='field'>
                                <label>Additional Information (optional)</label>
                                <input type='text' value={formData.info ?? ''} onChange={(e) => saveData('info', e.target.value)} />
                            </div>
                        </div>
                        <div className="g-space-2"></div>
                        <div className='how-to-distribute-row g-list-item grey-background'>
                            <h3>How do you want to distribute these rewards?</h3>
                            <hr />
                            <div className='button-row-1'> 
                                <button 
                                    className={`g-button ${formData.type === 'players' ? 'active': ''}`} 
                                    onClick={() => saveData('type', 'players')}
                                >
                                    Distribute to Players
                                </button>
                                <button 
                                    className={`g-button ${formData.type === 'teamTotals' ? 'active': ''} ${Object.values(teamList).length === 0 ? 'disabled' : ''}`} 
                                    onClick={() => saveData('type', 'teamTotals')}
                                >
                                    Distribute to Team Totals
                                </button>
                            </div>
                            <div className='g-space-1'></div>
                        </div>
                    </div>  
                    <div className='g-space-2'></div>
                    </>
                }
                {
                    (!review && formData.type === 'players') &&
                    <>
                    <div className='player-select g-list-item grey-background'>
                        <h3>Select Players</h3>
                        <hr />
                        <PlayerSelector 
                            playerList={playerList}
                            teamMembers={teamMembers}
                            gameElements={gameElements}
                            chosenPlayers={chosenPlayers}
                            togglePlayer={togglePlayer}
                            type='players'
                        />
                        <div className='g-space-1'></div>
                    </div>
                    <div className="g-space-2"></div>
                    </>
                }
                {
                    (!review && formData.type === 'teamTotals') &&
                    <>
                    <div className='player-select g-list-item grey-background'>
                        <h3>Select Teams</h3>
                        <hr />
                        <PlayerSelector 
                            playerList={playerList}
                            gameElements={gameElements}
                            chosenPlayers={chosenPlayers}
                            togglePlayer={togglePlayer}
                            type='teams'
                        />
                        <div className='g-space-1'></div>
                    </div>
                    <div className="g-space-2"></div>
                    </>
                }
                {
                    (!review && formData.type) &&
                    <div className={`earnings-form g-list-item grey-background ${chosenPlayers.length === 0 ? 'disabled' : ''}`}>
                        <h3>Set Default Rewards</h3>
                        <hr />
                        <RewardsForm
                            type='distribution' 
                            formData={formData}
                            saveData={saveData} 
                            gameElements={gameElements}
                            disabled={disabled}
                            gameData={gameData}
                            teams={formData.type === 'teamTotals'}
                        />
                        <hr />
                        <div className='buttons'>
                            <button className='g-button' onClick={() => cancelDistribution()}>Cancel</button>
                            <button className='g-button primary' onClick={() => setDefaultDistribution()}>Review Distribution</button>    
                        </div>
                    </div>
                }
                {
                    (review && chosenPlayers.length > 0 && Object.values(distributionObj).length > 0) &&
                    <div className='review-table g-list-item grey-background'>
                        <h3>Review Rewards</h3>
                        <hr />
                        <p>Reward Title: <b>{formData.title}</b></p>
                        {
                            (formData.info) &&
                            <h4>Additional Info: {formData.info}</h4>
                        }
                        <div className='review-list'>
                        {
                            (chosenPlayers.length > 0) &&
                            chosenPlayers.sort((a,b) => {
                                const pa = playerList[a];
                                const pb = playerList[b];
                                if ((pa.lName && pb.lName) && pa.lName.toLowerCase() > pb.lName.toLowerCase()) {
                                    return 1;
                                } else if ((pa.fName && pb.lName) && pa.fName.toLowerCase() > pb.lName.toLowerCase()) {
                                    return 1;
                                } else if ((pa.lName && pb.fName) && pa.lName.toLowerCase() > pb.fName.toLowerCase()) {
                                    return 1;
                                } else if ((pa.fName && pb.fName) && pa.fName.toLowerCase() > pb.fName.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })
                            .map(p => (
                                <div key={p} className='g-list-item review-list-item'> 
                                    <div className='info-row'>
                                        {
                                            (playerList[p].icon)
                                            ?
                                            <ImageContainer src={playerList[p].icon} className='icon' alt='group icon' />
                                            :
                                            <div className='gradient' style={{'background': playerList[p].gradient}}>{playerList[p].fName[0]}</div>
                                        }
                                        <div className='name'>{playerList[p].displayName}</div>
                                        <div className='distribution-content'>
                                            {
                                                (distributionObj[p].opt_earnedPoints) &&
                                                <div className='xp'>
                                                    <div className='g-list-item icon-box'>
                                                        <div>
                                                            <img src={gem} />
                                                        </div>
                                                        <div>
                                                            {distributionObj[p].opt_earnedPoints}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (distributionObj[p].opt_earnedCurrency) &&
                                                <div className='currency'>
                                                    <div className='g-list-item icon-box'>
                                                        <div>
                                                            <img src={coin} />
                                                        </div>
                                                        <div>
                                                            {distributionObj[p].opt_earnedCurrency}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (distributionObj[p].opt_earnedBadges && distributionObj[p].opt_earnedBadges.length > 0) &&
                                                <div className='badges'>
                                                    <div className='g-list-item icon-box'>
                                                        <div>
                                                            <FontAwesomeIcon icon={faCertificate} />
                                                        </div>
                                                        <div>
                                                        {
                                                            (distributionObj[p].opt_earnedBadges.length > 0)
                                                            ?
                                                            distributionObj[p].opt_earnedBadges.map(id => (
                                                                <div 
                                                                    key={p + id} 
                                                                    id={p + id} 
                                                                    title={gameElements[id].name}
                                                                >
                                                                    <ImageContainer
                                                                        keyId={p + id}
                                                                        className='icon' 
                                                                        src={gameElements[id].icon}
                                                                        alt={`${gameElements[id].icon} badge to distribute`}
                                                                        
                                                                    />
                                                                </div>
                                                            ))
                                                            :
                                                            'No badges'
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (distributionObj[p].opt_earnedItems && Object.values(distributionObj[p].opt_earnedItems).length > 0) &&
                                                <div className='items'>
                                                    <div className='g-list-item icon-box'>
                                                        <div>
                                                            <FontAwesomeIcon icon={faFlask} />
                                                        </div>
                                                        <div>
                                                        {
                                                            Object.values(distributionObj[p].opt_earnedItems).map(item => (
                                                                <div 
                                                                    key={item.id}
                                                                    title={gameElements[item.id].name}
                                                                >
                                                                    <ImageContainer
                                                                        keyId={item.id}
                                                                        className='icon' 
                                                                        src={gameElements[item.id].icon}
                                                                        alt={`${gameElements[item.id].icon} item to distribute`}
                                                                    />
                                                                    x{item.quantity}
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (distributionObj[p].opt_earnedPrizePacks) &&
                                                <div className='prize-packs'>Packs: {
                                                    'poop'
                                                }</div>
                                            }
                                        </div>
                                        <button className='g-button med-btn edit-button' onClick={() => toggleEditBox(p)}>Edit</button>
                                    </div>
                                    <div className='edit-box hidden' id={`edit-${p}`}>
                                        <RewardsForm
                                            type='distribution' 
                                            formData={distributionObj ? distributionObj[p] : {}}
                                            saveData={saveIndDistribution} 
                                            gameElements={gameElements}
                                            disabled={disabled}
                                            playerId={p}
                                            gameData={gameData}
                                            review={true}
                                            teams={formData.type === 'teamTotals'}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                        <div className="g-space-1"></div>
                        {
                            (loading)
                            ? 
                            <div className='buttons'>
                                <button className='g-button'>Back</button>
                                <button className='g-button'><ThreeDotsLoader /></button>
                            </div>
                            :
                            <div className='buttons'>
                                <button className='g-button' onClick={() => goBack()}>Back</button>
                                <button className='g-button primary' onClick={() => distributeRewards()}>Distribute Rewards</button>
                            </div>

                        }
                        
                    </div>
                }
            </div>
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(false)} 
                message={confirmMessage} 
                onApprove={approveRef.current} />
            <ModalAlert 
                show={alertMessage} 
                cancel={() => setAlertMessage(false)} 
                message={alertMessage} />
            <FullPageLoader 
                show={loading} 
                text={loaderText} />
        </div>
    )
}

export default DistributePage