import React, { useEffect } from 'react'
import './create-elements-container.styles.scss';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CreateElementsLanding from './create-elements-landing.component';
import ChallengesLandingPage from './challenges/challenges-landing.component';
import LevelsLandingPage from './levels/levels-landing.component';
import BadgesLandingPage from './badges/badges-landing.component';
import ItemsLandingPage from './items/items-landing.component';
import SecretCodesLandingPage from './secret-codes/secret-codes-landing.component';
import CollectionsLandingPage from './collections/collections-landing.component';
import ElementDataDisplay from './data-display/element-data-display.component';

const GameElements = ({ 
    gameData, 
    gameElements, 
    playerList, 
    gameStats,
    gameActivity,
    role
}) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!role) return;
        if (role !== 'owner' && role !== 'manager') {
            navigate(-1);
        }
    }, [role])
    
    return (
        <div className='create-elements-container'>
            <div className='create-elements-content'>
                <Routes>
                    <Route path='' element={
                        <CreateElementsLanding 
                            gameData={gameData} 
                            gameElements={gameElements} 
                        />
                    }></Route>
                    <Route path='challenges/*' element={
                        <ChallengesLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                        />
                    }></Route>
                    <Route path='levels/*' element={
                        <LevelsLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                        />
                    }></Route>
                    <Route path='badges/*' element={
                        <BadgesLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                        />
                    }></Route>
                    <Route path='items/*' element={
                        <ItemsLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                        />
                    }></Route>
                    <Route path='secretcodes/*' element={
                        <SecretCodesLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList}
                        />
                    }></Route>
                    <Route path='collections/*' element={
                        <CollectionsLandingPage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                        />
                    }></Route>
                    <Route path='data/:elementPath' element={
                        <ElementDataDisplay
                            gameData={gameData}
                            gameStats={gameStats}
                            gameElements={gameElements}
                            playerList={playerList}
                            gameActivity={gameActivity}
                        />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </div>
        </div>
    )
}

export default GameElements