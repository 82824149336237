import { query as fbQuery, collection, where, onSnapshot, and, doc, getDoc, orderBy, getDocs, limit, limitToLast } from "firebase/firestore";
import { db, rtDb } from "./firebase.utils";
import { equalTo, onChildAdded, onChildChanged, onChildRemoved, orderByChild, ref, query as rtQuery} from "firebase/database";
import sizeof from "firestore-size";


export async function getRegsFromDb(e) {
    let regsRef;

    if (e.appId && e.status) {
        regsRef = fbQuery(collection(db, `regs`), and(where('list', 'array-contains', e.appId), where('status', '==', e.status)));
    } else if (e.gameId) {
        regsRef = fbQuery(collection(db, 'regs'), where('gameId', '==', e.gameId));
    }
    const unsubscribe = onSnapshot(regsRef, docs => {
        docs.forEach(async doc => {
            e.callback(doc.data());
        });
    })
    e.handleListener(unsubscribe);
}

export async function getGameDataFromDb(e) {
    let gameRef;

    if (e.gameId) {
        gameRef = doc(db, 'games', e.gameId);
    } else if (e.joinCode && e.status){
        gameRef = fbQuery(collection(db, 'games'), and(where('joinCode', '==', e.joinCode), where('status', '==', e.status)))
    } else if (e.gamePath && e.status) {
        gameRef = fbQuery(collection(db, 'games'), and(where('path', '==', e.gamePath), where('status', '==', e.status)))
    }
    // console.log(gameRef);

    if (e.once) {
        const gameSnap = await getDoc(gameRef)
        const gameData = gameSnap.data();
        const lastActivity = await getLastActivity(gameData.gameId);
        // console.log(lastActivity);
        if (!lastActivity) {
            gameData.lastActivity = gameData.ts;    
        } else {
            gameData.lastActivity = lastActivity.ts;
        }
        if (e.appId) {
            const lastUserActivity = getLastActivity(gameData.gameId, e.appId);
            if (lastUserActivity) {
                gameData.lastUserActivity = lastUserActivity.ts;
            } 
        }
        e.callback(gameData);
        return;
    } else {
        const unsubscribe = onSnapshot(gameRef, docs => {
            docs.forEach(async doc => {
                let gameData = doc.data();
                e.callback(gameData);
            });
        })
        e.handleListener(unsubscribe);
    }
}

export async function getLastActivity(gameId, appId) {
    let activityRef;
    if (gameId && appId) {
        activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', gameId), where('appId', '==', appId)), orderBy("ts", "desc"), limit(1))
    } else if (gameId) {
        activityRef = fbQuery(collection(db, 'gameActivity'), where('gameId', '==', gameId), orderBy("ts", "desc"), limit(1))
    }
    const activitySnap = await getDocs(activityRef);
    // console.log(activitySnap.size);
    if (activitySnap.size === 0) return false;
    let lastActivity;
    activitySnap.forEach(doc => {
        const activityData = doc.data();
        // console.log(activityData);
        lastActivity = activityData;
    })
    return lastActivity;
}

export async function getPlayerDataFromDb(e) {
    let playerRef;
    // console.log(e)

    if (e.appId && e.gameId) {
        playerRef = fbQuery(collection(db, 'players'), and(where('gameId', '==', e.gameId), where('appId', '==', e.appId)))
    } else if (e.gameId && e.enrollMethod) {
        playerRef = fbQuery(collection(db, 'players'), and(where('gameId', '==', e.gameId), where('enroll_method', '==', e.enrollMethod)))
    } else if (e.gameId && e.removed) {
        playerRef = fbQuery(collection(db, 'players'), and(where('gameId', '==', e.gameId), where('status', '!=', 'active')));
    } else if (e.gameId) {
        playerRef = fbQuery(collection(db, 'players'), where('gameId', '==', e.gameId));
    }

    const unsubscribe = onSnapshot(playerRef, docs => {
        docs.forEach(async doc => {
            let playerData = doc.data();
            e.callback(playerData);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getPlayersFromPlayerDocs(e) {
    let playerDocRef = fbQuery(collection(db, 'gamePlayerDocs'), where('gameId', '==', e.gameId));

    const unsubscribe = onSnapshot(playerDocRef, docs => {
        docs.forEach(async doc => {
            let docData = doc.data();
            e.callback(docData);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getGameElementsFromDb(e) {
    let elementsRef;

    // if (e.gameId && e.type && e.status) {
    //     elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('type', '==', e.type), where('status', '==', e.status)));
    // } else if (e.gameId && e.status && e.lastDate) {
    //     elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('status', '==', e.status), where('ts', '>', e.lastDate)));
    // } else if (e.gameId && e.status) {
    //     elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('status', '==', e.status)));
    // }
    if (e.gameId) {
        elementsRef = fbQuery(collection(db, 'gameElementDocs'), where('gameId', '==', e.gameId))
    }

    const unsubscribe = onSnapshot(elementsRef, docs => {
        docs.forEach(async doc => {
            let elementsData = doc.data();
            e.callback(elementsData);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getGameElementsFromDbForPlayers(e) {
    let elementsRef;

    if (e.gameId && e.type && e.status) {
        elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('type', '==', e.type), where('status', '==', e.status), where('public', '==', true), where('opt_visible', '==', true)));
    } else if (e.gameId && e.status && e.lastDate) {
        elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('status', '==', e.status), where('ts', '>', e.lastDate), where('public', '==', true)));
    } else if (e.gameId && e.status) {
        elementsRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('status', '==', e.status), where('public', '==', true), where('opt_visible', '==', true)));
    }

    const unsubscribe = onSnapshot(elementsRef, docs => {
        docs.forEach(async doc => {
            let elementsData = doc.data();
            e.callback(elementsData);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getSpecificGameElementFromDb(e) {
    let elementRef;

    if (e.path && e.gameId && e.status) {
        elementRef = fbQuery(collection(db, 'gameElements'), and(where('gameId', '==', e.gameId), where('path', '==', e.path), where('status', '==', e.status)));
    } 

    const unsubscribe = onSnapshot(elementRef, docs => {
        docs.forEach(async doc => {
            let elementData = doc.data();
            e.callback(elementData);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getPlayerActivityFromDb(e) {
    let activityRef;

    if (e.gameId && e.playerId && e.display && e.lastActivity && e.limit) {
        activityRef = fbQuery(
            collection(db, 'gameActivity'), 
            and(
                where('gameId', '==', e.gameId), 
                where('playerId', '==', e.playerId),
                where('display', '==', e.display),
                where('ts', '<', e.lastActivity)
            ),
            limit(e.limit)

        );
    } else if (e.gameId && e.playerId && e.display && e.limit) {
        activityRef = fbQuery(
            collection(db, 'gameActivity'), 
            and(
                where('gameId', '==', e.gameId), 
                where('playerId', '==', e.playerId),
                where('display', '==', e.display)
            ),
            limit(e.limit)
        );
    } else if (e.gameId && e.playerId && e.display) {
        activityRef = fbQuery(
            collection(db, 'gameActivity'), 
            and(
                where('gameId', '==', e.gameId), 
                where('playerId', '==', e.playerId),
                where('display', '==', e.display)
            )
        );
    }

    if (!e.handleListener) {
        getDocs(activityRef, docs => {
            docs.forEach(async doc => {
                let activityData = doc.data();
                e.callback(activityData);
            });
        })
    } else {
        const unsubscribe = onSnapshot(activityRef, docs => {
            docs.forEach(async doc => {
                let activityData = doc.data();
                e.callback(activityData);
            });
        })
        e.handleListener(unsubscribe);
    }
}



export async function getGameActivityFromDb(e) {
    let activityRef;

    // if (e.gameId && e.sevenDays) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('ts', '>=', e.sevenDays)));
    // } else if (e.gameId && e.startDate) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('ts', '<', e.startDate)));
    // } else if (e.gameId && e.startDate && e.more) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('ts', '<', e.startDate)), limit(10));
    // } else if (e.gameId && e.playerId && e.lastDate) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('playerId', '==', e.playerId), where('ts', '>', e.lastDate)));
    // } else if (e.gameId && e.playerId && e.limit) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('playerId', '==', e.playerId)), limit(e.limit));
    // } else if (e.gameId && e.playerId) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('playerId', '==', e.playerId)));
    // } else if (e.gameId && e.activity) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('activity', '==', e.activity)));
    // } else if (e.gameId && e.lastDate) {
    //     activityRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('ts', '>', e.lastDate)))
    // } else 
    if (e.gameId) {
        activityRef = fbQuery(collection(db, 'gameActivityDocs'), where('gameId', '==', e.gameId));
    }

    const unsubscribe = onSnapshot(activityRef, docs => {
        // let dates = []
        // let count = 0;
        docs.forEach(doc => {
            let activityData = doc.data();
            e.callback(activityData);
            // if (!e.getAll) { 
            //     dates.push(activityData.ts);
            //     count++
            //     if (count === docs.size && !e.player) {
            //         if (docs.size < 10) {
            //             fillTwenty(dates.sort(), docs);
            //         }
            //     }
            // }
        });
        // if (e.more && docs.size < 10) {
        //     e.callback({'done': {'ts': 0, 'done': true}});
        // }
    })
    if (e.handleListener) {
        e.handleListener(unsubscribe);
    }

    

    // async function fillTwenty(dates, docs) {
    //     let twentyMoreRef;
    //     if (e.gameId && e.sevenDays) {
    //         const lastDate = dates[0]
    //         twentyMoreRef = fbQuery(collection(db, 'gameActivity'), and(where('gameId', '==', e.gameId), where('ts', '<', lastDate)), limit(10-docs.size));
    //     }

    //     const querySnap = await getDocs(twentyMoreRef);
    //     querySnap.forEach(doc => {
    //         e.callback(doc.data())
    //     })
    // }
}

export async function getActionItemsFromDb(e) {
    let actionItemsRef;
    if (e.gameId) {
        actionItemsRef = fbQuery(collection(db, 'actionItemDocs'), where('gameId', '==', e.gameId));
    } 

    const unsubscribe = onSnapshot(actionItemsRef, docs => {
        docs.forEach(async doc => {
            let actionItem = doc.data();
            e.callback(actionItem);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getLiveDataFromDb(e) {
    let liveRef;

    if (e.path) {
        liveRef = fbQuery(collection(db, 'leaderboards'), and(where('path', '==', e.path), where('status', '==', e.status), where('type', '==', 'leaderboard')));
    }

    const unsubscribe = onSnapshot(liveRef, docs => {
        docs.forEach(doc => {
            console.log(doc.data());
            e.callback(doc.data());
        })
    })
    e.handleListener(unsubscribe);
}

export async function getLeaderboardDataFromDb(e) {
    let leaderboardRef;

    if (e.gameId) {
        leaderboardRef = fbQuery(collection(db, 'gamePlayerDocs'), where('gameId', "==", e.gameId));
    }

    const unsubscribe = onSnapshot(leaderboardRef, docs => {
        let playerObj = {}
        docs.forEach(doc => {
            const data = doc.data();
            playerObj = {...playerObj, ...data.players}
            
        });
        e.callback(playerObj);
    });
    e.handleListener(unsubscribe);
}

export async function getUserMembershipFromDb(e) {
    let membershipRef;

    if (e.appId) {
        membershipRef = doc(db, 'memberships', e.appId);
    }

    const unsubscribe = onSnapshot(membershipRef, doc => {
        const data = doc.data();
        e.callback(data)
    });
    e.handleListener(unsubscribe);
    
}

export async function getOrgInvitesFromDb(e) {
    let inviteRef;

    if (e.email) {
        inviteRef = fbQuery(collection(db, 'orgInvites'), where('email', '==', e.email));
    }
    const unsubscribe = onSnapshot(inviteRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data, doc.id);
        });
    });
    e.handleListener(unsubscribe);
}

export async function getOrgDataFromDb(e) {
    let orgRef;
    if (e.orgId && e.status && e.current) {
        orgRef = fbQuery(collection(db, 'orgs'), 
            and(
                where('orgId', '==', e.orgId), 
                where('status', '==', e.status),
                where('dateExpire', '>=', new Date().getTime())
            )
        );
    } else if (e.orgId) {
        orgRef = fbQuery(collection(db, 'orgs'),
            where('orgId', '==', e.orgId)
        )
    }

    const unsubscribe = onSnapshot(orgRef, docs => {
        docs.forEach(doc => {
            if (!doc.exists) return;
            e.callback(doc.data(), doc.id);
        })
    })
    e.handleListener(unsubscribe);
}

export async function getUniqueDocumentFromDb(e) {
    let docRef = doc(db, e.collection, e.docId);

    const unsubscribe = onSnapshot(docRef, doc => {
        const data = doc.data();
        e.callback(data, doc.id)
    });
    e.handleListener(unsubscribe);
}

export async function getCollectionFromDb(e) {
    let collRef;

    if (e.sort1) {
        collRef = fbQuery(collection(db, e.collection), where(e.sort1.key, '==', e.sort1.value));
    } else {
        collRef = collection(db, e.collection);
    }

    const unsubscribe = onSnapshot(collRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data, doc.id);
        })
        e.handleListener(unsubscribe);
    })
}

export async function getLatestGameMessageFromDb(e) {
    let messageRef = fbQuery(collection(db, 'messages'), where('gameId', '==', e.gameId), orderBy('ts'), limitToLast(1))
    const unsubscribe = onSnapshot(messageRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data, doc.id);
        })
        e.handleListener(unsubscribe);
    })
}
